<template>
  <div class="setting-page">
    <HeadNav/>
		
		<div>
			<yd-cell-group v-if="businessInfo.id != 2311 && businessInfo.id != 2265">
				<div v-if="!tripartite('VUE_APP_TABEN') && !tripartite('VUE_APP_BDT') && fk_show&&!customAccount('VUE_APP_CHAOWEI') && zgyh_show">
					<yd-cell-item
						v-if="!customAccounttype && !pointsTopic"
						arrow
						type="link"
						:href="{name:'verify_phone',query:{function_type:1}}"
					>
						<span slot="left">修改支付密码</span>
					</yd-cell-item>
					<yd-cell-item arrow type="link" v-if="!tripartite('VUE_APP_GUANGDALOGIN')&&!customAccount('VUE_APP_CHAOWEI') && jy_show" :href="{name:'set_password'}">
						<span slot="left">修改登录密码</span>
					</yd-cell-item>
					<yd-cell-item
						v-if="phone && !customAccounttype && !pointsTopic && jy_show"
						arrow
						type="link"
						:href="{name:'verify_phone',query:{function_type:2}}"
					>
						<span slot="left">换绑手机号</span>
						<span slot="right">{{phone}}</span>
					</yd-cell-item>
				</div>
				<yd-cell-item
					v-if="!fk_show"
					arrow
					type="link"
					:href="{name:'fk_set_password'}"
				>
					<span slot="left">修改账号密码</span>
				</yd-cell-item>
				<div
					v-if="wxStatus && !tripartite('VUE_APP_TABEN') && fk_show && !customAccounttype"
					@click.stop="unbindWeChat"
				>
					<yd-cell-item arrow>
						<span slot="left">解绑微信</span>
					</yd-cell-item>
				</div>
			</yd-cell-group>
			
			<yd-cell-group>
				<yd-cell-item
					arrow type="link"
					href="/integral_rules?article_tag=about_us"
				>
					<span slot="left">关于我们</span>
				</yd-cell-item>
				<yd-cell-item arrow type="link" href="/help_center">
					<span slot="left">帮助中心</span>
				</yd-cell-item>
			</yd-cell-group>
		</div>
		
		<div v-if="pointsTopic">
			<yd-cell-grop>
			 <div v-if="wxStatus" class="topic-point" @click.stop="unbindWeChat">
					<yd-cell-item arrow>
						<span slot="left">解绑微信</span>
					</yd-cell-item>
				</div>
			</yd-cell-grop>
		</div>
		
    <Footer>
      <!--    版权信息-->
      <div class="copyright-box">
        <div>Copyright©2018-{{year}}</div>
        <div>壹企通提供技术支持</div>
      </div>
      <div
				v-if="businessInfo.id != 2311 && businessInfo.id != 2265 && !tripartite('VUE_APP_BDT') && loginBtn && zgyh_show && jy_show"
				@click.stop="logout"
				class="logoutBtn"
			>退出登录</div>
    </Footer>
  </div>
</template>

<script>
import {mapState, mapMutations} from 'vuex'
import {logout} from "@/services";
import { unbindWx } from "@/services/user";
import { getStore, setStore,isWeiXin } from "@/utils/common";
import { tripartite, customAccount } from "@/utils/custom";
// import AlertTip from '@/components/common/AlertTip';
import HeadNav from "@/components/common/HeadNav";

export default {
  name: "setting",
  components: {
    HeadNav
  },
  data() {
    return {
    wxStatus: false,
    year: '',
    fk_show: true,
	zgyh_show:true,   //中行显隐
    // customAccount: false,  //  是否是定制账号登录
    customAccounttype: false,  //  是否是定制账号登录
    pointsTopic: false,  //  海元素定制商城  
	loginBtn:true,       //海元素商城 app联合登录 退出登录按钮不展示
	jy_show:true, //江阴控制显隐
	}
  },
  computed: {
    ...mapState({
      //手机号码
      phone: state => state.userInfo.mobile ? state.userInfo.mobile : '',
      type: state => state.businessInfo.type,//1现金商城，2积分商品，3内采商城
      businessInfo: state => state.businessInfo,
      seaStatus: state => state.seaStatus  //  海元素定制商城
    }),
  },
  mounted() {
    //获取用户信息
    //this.$store.dispatch('getUserInfo')
  this.getWxStatus();
  let date=new Date;
  this.year=date.getFullYear(); 
    if ( customAccount('VUE_APP_ACCOUNT') ) {
      this.customAccounttype = true
    }
    this.isPointSopic()
  },
  methods: {
    ...mapMutations([
      "clear"
    ]),

	tripartite,
    customAccount,
	//饭卡商城显隐
	isFk(){
		if (process.env.VUE_APP_FK) {
			let bids = process.env.VUE_APP_FK.split(',');
			bids.forEach( val => {
				if (val == JSON.parse(getStore('businessInfo')).id) {
					this.fk_show = false;
					return;
				}
			});
		}
	},

	// 中行显隐控制
    isZh(){
     	if (process.env.VUE_APP_ZHONGHANG) {
	 		let bids = JSON.parse(process.env.VUE_APP_ZHONGHANG);
	 		bids.forEach( val => {
	 			if (val == JSON.parse(getStore('businessInfo')).id) {
	 				this.zgyh_show = false;
	 				return;
	 			}
	 		});
	 	}
    },

	// 江阴显隐控制
    isJy(){
     	if (process.env.VUE_APP_JY) {
	 		let bids = JSON.parse(process.env.VUE_APP_JY);
	 		bids.forEach( val => {
	 			if (val == JSON.parse(getStore('businessInfo')).id) {
	 				this.jy_show = false;
	 				return;
	 			}
	 		});
	 	}
    },

	//获取用户微信绑定状态
	getWxStatus () {
		let openId = JSON.parse(getStore("userInfo")).openid;
		if (openId) {
			this.wxStatus = true;
		} else{
			this.wxStatus = false;
		}
	},
	
	//解绑微信
	async unbindWeChat () {
		try {
			const res = await unbindWx();
			if (res.code === 0) {
				this.$dialog.toast({
				  mes: '微信解绑成功',
				  icon: 'success',
				});
				let inf = JSON.parse(getStore("userInfo"));
				inf.openid = "";
				setStore("userInfo", JSON.stringify(inf));
				this.wxStatus = false;
			} else {
        this.$dialog.toast({
          mes: res.msg,
          icon: 'error',
        });
      }
		} catch (e) {
			console.warn(e);
		}
	},
	
    //退出登录
    async logout(){
		try {
			const res = await logout();
			if (res.code === 0) {
				sessionStorage.removeItem('show')
				localStorage.removeItem('userInfo')
				localStorage.removeItem('code')
				localStorage.removeItem('generalize_code')
				// localStorage.clear()
				sessionStorage.clear()
				this.$dialog.toast({
					mes: '退出登录成功',
					icon: 'success',
				});
				this.clear();
				localStorage.removeItem('has_input_password');
				if (tripartite('VUE_APP_TABEN')) {
          this.$router.replace('tabenLogin');
        } else if(this.seaStatus) {
          this.$router.push('/login')
				} else if(customAccount('VUE_APP_CHAOWEI')){
					this.$router.push('/login')
				}else {
					this.$router.replace('home');
				}

			}
		} catch (e) {
			console.warn(e);
		}
    },

    isPointSopic() {  //海元素登录按钮不展示
      if ( customAccount('VUE_APP_POINTS_TOPIC') ) {
		this.pointsTopic = true
		if(!isWeiXin()){
          this.loginBtn = false 
		}
      }
    }
  },
  created() {
    this.isFk();
	this.isZh();
	this.isJy();
  },
}
</script>

<style lang="less">
@import "../../../common/less/variable.less";
@import "../../../common/less/mixin.less";

.setting-page {

  .topic-point {
    background: #fff;
  }
  .yd-cell-item {
    padding-left: 0.36rem;
  }

  .yd-cell-left {
    span {
      .sc(0.3rem, #2D2D2D)
    }
  }

  .yd-cell-right {
    height: 1rem;

    span {
      .sc(0.3rem, @color-light-grey)
    }
  }

  .yd-cell-item:not(:last-child):after {
    margin-left: 0.36rem;
  }

  .yd-cell:after {
    width: 0;
  }
  Footer{
    width: 100%;
    position: absolute;
    bottom: 0;
    /*  版权信息*/

    .copyright-box {
      .sc(0.28rem, @color-light-grey);
      margin-bottom: 0.38rem;
      div {
        height: 0.4rem;
        line-height: 0.4rem;
        text-align: center;
      }
    }

    .logoutBtn {
      .wh(100%, 0.96rem);
      background: rgba(255, 82, 82, 1);
      .sc(0.28rem, @color-white);
      line-height: 0.96rem;
      text-align: center;
    }
  }

}
</style>
