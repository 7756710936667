var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "setting-page" },
    [
      _c("HeadNav"),
      _c(
        "div",
        [
          _vm.businessInfo.id != 2311 && _vm.businessInfo.id != 2265
            ? _c(
                "yd-cell-group",
                [
                  !_vm.tripartite("VUE_APP_TABEN") &&
                  !_vm.tripartite("VUE_APP_BDT") &&
                  _vm.fk_show &&
                  !_vm.customAccount("VUE_APP_CHAOWEI") &&
                  _vm.zgyh_show
                    ? _c(
                        "div",
                        [
                          !_vm.customAccounttype && !_vm.pointsTopic
                            ? _c(
                                "yd-cell-item",
                                {
                                  attrs: {
                                    arrow: "",
                                    type: "link",
                                    href: {
                                      name: "verify_phone",
                                      query: { function_type: 1 },
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { attrs: { slot: "left" }, slot: "left" },
                                    [_vm._v("修改支付密码")]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          !_vm.tripartite("VUE_APP_GUANGDALOGIN") &&
                          !_vm.customAccount("VUE_APP_CHAOWEI") &&
                          _vm.jy_show
                            ? _c(
                                "yd-cell-item",
                                {
                                  attrs: {
                                    arrow: "",
                                    type: "link",
                                    href: { name: "set_password" },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { attrs: { slot: "left" }, slot: "left" },
                                    [_vm._v("修改登录密码")]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.phone &&
                          !_vm.customAccounttype &&
                          !_vm.pointsTopic &&
                          _vm.jy_show
                            ? _c(
                                "yd-cell-item",
                                {
                                  attrs: {
                                    arrow: "",
                                    type: "link",
                                    href: {
                                      name: "verify_phone",
                                      query: { function_type: 2 },
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { attrs: { slot: "left" }, slot: "left" },
                                    [_vm._v("换绑手机号")]
                                  ),
                                  _c(
                                    "span",
                                    { attrs: { slot: "right" }, slot: "right" },
                                    [_vm._v(_vm._s(_vm.phone))]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.fk_show
                    ? _c(
                        "yd-cell-item",
                        {
                          attrs: {
                            arrow: "",
                            type: "link",
                            href: { name: "fk_set_password" },
                          },
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "left" }, slot: "left" },
                            [_vm._v("修改账号密码")]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.wxStatus &&
                  !_vm.tripartite("VUE_APP_TABEN") &&
                  _vm.fk_show &&
                  !_vm.customAccounttype
                    ? _c(
                        "div",
                        {
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.unbindWeChat.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c("yd-cell-item", { attrs: { arrow: "" } }, [
                            _c(
                              "span",
                              { attrs: { slot: "left" }, slot: "left" },
                              [_vm._v("解绑微信")]
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _c(
            "yd-cell-group",
            [
              _c(
                "yd-cell-item",
                {
                  attrs: {
                    arrow: "",
                    type: "link",
                    href: "/integral_rules?article_tag=about_us",
                  },
                },
                [
                  _c("span", { attrs: { slot: "left" }, slot: "left" }, [
                    _vm._v("关于我们"),
                  ]),
                ]
              ),
              _c(
                "yd-cell-item",
                { attrs: { arrow: "", type: "link", href: "/help_center" } },
                [
                  _c("span", { attrs: { slot: "left" }, slot: "left" }, [
                    _vm._v("帮助中心"),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.pointsTopic
        ? _c(
            "div",
            [
              _c("yd-cell-grop", [
                _vm.wxStatus
                  ? _c(
                      "div",
                      {
                        staticClass: "topic-point",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.unbindWeChat.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c("yd-cell-item", { attrs: { arrow: "" } }, [
                          _c(
                            "span",
                            { attrs: { slot: "left" }, slot: "left" },
                            [_vm._v("解绑微信")]
                          ),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c("Footer", [
        _c("div", { staticClass: "copyright-box" }, [
          _c("div", [_vm._v("Copyright©2018-" + _vm._s(_vm.year))]),
          _c("div", [_vm._v("壹企通提供技术支持")]),
        ]),
        _vm.businessInfo.id != 2311 &&
        _vm.businessInfo.id != 2265 &&
        !_vm.tripartite("VUE_APP_BDT") &&
        _vm.loginBtn &&
        _vm.zgyh_show &&
        _vm.jy_show
          ? _c(
              "div",
              {
                staticClass: "logoutBtn",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.logout.apply(null, arguments)
                  },
                },
              },
              [_vm._v("退出登录")]
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }